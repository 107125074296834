import React from 'react'
import { Button, Form } from 'react-bootstrap'

const Form2CompanyDetails = (props) => {
  const { handleChange, values, handleDocumentUpload } = props
  const goContinue = (e) => {
    const form = e.currentTarget.form
    console.log(e.currentTarget.form)
    if (form.checkValidity() === false) {
      // e.preventDefault()
      e.stopPropagation()
    } else if (form.checkValidity() === true) {
      e.preventDefault()
      console.log(values.companyDocumentFile)
      props.nextStep()
    }
  }

  const goBack = (e) => {
    e.preventDefault()
    props.prevStep()
  }

  const uploadDocument = (e) => {
    e.preventDefault()
    handleDocumentUpload('companyDocument')
  }

  const companyDocument = values.companyDocument

  return (
    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5">
      {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
      <div className="d-flex justify-content-between">
        <h4 className="mb-3 font-weight-bold inter-font-content">
          Provide your company details{' '}
        </h4>
        <h4 className="mb-3 font-weight-bold inter-font-content">3/4</h4>
      </div>
      {/* TODO hide for now. It is part of part 2 */}
      {/*
        <p>
          Join an existing company? Click <Link to="/login">here</Link>
        </p>
      */}
      {/* form */}
      <Form className="d-flex flex-column flex-grow-1 justify-content-between">
        <div>
          <Form.Group>
            <Form.Label>Company name</Form.Label>
            <Form.Control
              type="text"
              autoFocus={true}
              value={values.companyName}
              autoComplete="organization"
              required
              onChange={handleChange('companyName')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company registration number</Form.Label>
            <Form.Control
              type="text"
              value={values.companyRegiNum}
              required
              autoComplete="nope"
              onChange={handleChange('companyRegiNum')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Upload company registration document</Form.Label>
            <Form.File
              // required
              className="form-control"
              label={
                values.companyDocument
                  ? values.companyDocument
                  : 'No file chosen'
              }
              data-browse="Choose File"
              onChange={handleDocumentUpload('companyDocument')}
              custom
            />
          </Form.Group>
        </div>
        <Form.Group>
          <Form.Label>Company tax ID</Form.Label>
          <Form.Control
            type="text"
            value={values.companyTaxId}
            autoComplete="nope"
            onChange={handleChange('companyTaxId')}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Company website</Form.Label>
          <Form.Control
            type="text"
            value={values.companyWebsite}
            autoComplete="nope"
            onChange={handleChange('companyWebsite')}
          />
        </Form.Group>

        <Form.Group className="mb-0 d-flex justify-content-between">
          <Button
            variant="outline-primary"
            type="button"
            style={{ width: 100 + 'px' }}
            onClick={(e) => goBack(e)}
          >
            <span>Back</span>
          </Button>
          <Button
            className="mt-0 "
            type="submit"
            // disabled={loginMutation.isLoading || credentials.username.length === 0 || credentials.password.length === 0}
            onClick={(e) => goContinue(e)}
          >
            <span>Continue</span>
          </Button>
        </Form.Group>
      </Form>
    </div>
  )
}

export default Form2CompanyDetails
